import React from 'react';
import Navbar from './Navbar';
import Social from './Social';

function Contact() {
  return (
    <div className="min-h-screen bg-black text-white">

              {/* Navbar */}
      <Navbar bgColor="bg-black" />

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
         
          {/* Contact Form */}
          <div className=" shadow-lg rounded-lg p-4">
            <h2 className="text-2xl font-bold mb-4">İletişim </h2>
            <div className="">
                <div className=" shadow-lg rounded-lg">
                <div className="block font-bold text-lg mb-4">Çalışma Saatleri</div>
                <table className="min-w-full table-auto">
                    <thead>
                    <tr>
                        <th className="text-left px-4 py-2  font-semibold">Gün</th>
                        <th className="text-left px-4 py-2  font-semibold">Saat</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="">
                        <td className="border px-4 py-2">Pazartesi - Cuma</td>
                        <td className="border px-4 py-2">08:00 - 23:00</td>
                    </tr>
                    <tr className="">
                        <td className="border px-4 py-2">Cumartesi</td>
                        <td className="border px-4 py-2">08:00 - 23:00</td>
                    </tr>
                    <tr className="">
                        <td className="border px-4 py-2">Pazar</td>
                        <td className="border px-4 py-2">Kapalı</td>
                    </tr>
                    </tbody>
                </table>
                </div>

            <div className=" shadow-lg rounded-lg py-4">
                <div className="block font-bold text-lg ">Telefon</div>
                <h1 className="text-left font-semibold">0 544 2718081</h1>
            </div>

            <div className="shadow-lg rounded-lg py-4">
                <div className="block font-bold text-lg ">Adres</div>
                <h1 className="text-left font-semibold">Güzelbahçe, Hanbaga Burger, 4. Düzce Bulvarı no:25/A, 81630 Düzce Merkez/Düzce</h1>
            </div>
            <div className="shadow-lg rounded-lg py-4">
                <div className="block font-bold text-lg ">Bizi takip edin</div>
                <Social />
            </div>
            </div>
          </div>
          {/* Google Maps */}
          <div className="mb-5 rounded-lg overflow-hidden border border-gray-300"> {/* Added border class */}
              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3017.507982343232!2d31.2290909!3d40.860724999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409d0b2b258616d3%3A0x66a2bac42fc5868a!2sHanbaga%20Burger!5e0!3m2!1sen!2str!4v1727099940310!5m2!1sen!2str"
                width="100%"
                height="550"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                className="w-full h-full" // The iframe itself will also take full width and height
              ></iframe>
            </div>
        </div>
      </main>
    </div>
  );
}

export default Contact;
