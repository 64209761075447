// import React, { useEffect, useState } from "react";
import { Routes , Route } from 'react-router-dom'
import Home from './components/Home'
import Menu from './components/Menu';
import Contact from './components/Contact';
import Missing from './Missing';
// import AdminPage from './AdminPage'
// import ProtectedRoutes from './utils/ProtectedRoutes';

function App() {

  return (  
    <>    
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/contact" element={<Contact />} />

        {/* We want to protect these routes */}
        {/* <Route element={<ProtectedRoutes />}>           
          <Route path="/admin" element={<AdminPage />} /> 
        </Route> */}
        
        {/* Catch All */}
        <Route path="*" element={<Missing />} />         
      </Routes>

      </>
      )
}

export default App;
