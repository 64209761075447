import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
// import menuItems from '../utils/menuItems'

function Menu() {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await fetch("/menuItems.json"); // Update the path as needed
        const data = await response.json();
        setMenuItems(data);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };

    fetchMenuItems();
  }, []);

  return (
    <div className="min-h-screen bg-black">
      {/* Navbar */}
      <Navbar />

      {/* Main Content */}
      <main className="mt-10">
        <section className="max-w-4xl mx-auto text-center">
          <div>
            <p className=" text-2xl font-bold text-orange-500">
              Öğrencilerimize özel
            </p>
            <span className="uppercase pl-3 italic text-2xl font-extrabold underline text-orange-600   decoration-orange-500">
              %10 İndirim !!
            </span>

            <h2 className="pt-4 text-4xl font-bold mb-2 text-white ">
              Menümüz
            </h2>

            <h2 className="text-2xl mb-8 text-white">Afiyet olsun</h2>
          </div>
          <div className=" text-white grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {menuItems.map((item) => (
              <div key={item.name} className=" shadow-lg rounded-lg p-6">
                <img
                  src={item.image}
                  alt={item.name}
                  className="w-full h-48 object-cover rounded-lg mb-4"
                />
                <h3 className="text-2xl font-bold mb-2">{item.name}</h3>
                <p className="">{item.description}</p>
                <p className="mt-2 font-semibold">{item.price} TL</p>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
}

export default Menu;
