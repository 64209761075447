import React from 'react';
import { FaWhatsapp, FaInstagram } from 'react-icons/fa';

const Social = () => {
  return (
    <div className="flex space-x-2">
      {/* WhatsApp Link */}
      <a
        href="https://wa.me/05442718081" // Replace with your WhatsApp number
        target="_blank"
        rel="noopener noreferrer"
        className="text-green-500 hover:text-green-700"
      >
        <FaWhatsapp size={30} />
      </a>
      
      {/* Instagram Link */}
      <a
        href="https://www.instagram.com/hanbagaburger?igsh=MTVpM2d1OWdsaWw0eQ==" // Replace with your Instagram username
        target="_blank"
        rel="noopener noreferrer"
        className="text-pink-500 hover:text-pink-700"
      >
        <FaInstagram size={30} />
      </a>
    </div>
  );
};

export default Social;
