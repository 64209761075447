import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar"; // Assuming the Navbar is in the same directory

function Home() {
  return (
    <div className="relative h-screen overflow-hidden">
      {/* Navbar */}
      {/* <div className="bg-gray-100 shadow-lg">
        <Navbar />
      </div> */}
      <Navbar />
      {/* Background Video */}
      <video
        autoPlay
        playsInline
        loop
        muted
        className="absolute top-0 left-0 below-20 w-full h-full object-cover -z-10 "
        src="/videos/bagalamak.mp4"
      />

      {/* Overlay Content */}
      <div className="flex flex-col justify-center items-center h-screen text-center px-4">
        <h1 className="text-5xl md:text-6xl font-bold text-hanbagacolor">
          Hanbaga Burger'e Hoşgeldiniz
        </h1>
        <p className=" text-2xl font-bold text-orange-500 pt-5">
          Öğrencilerimize özel
        </p>
        <span className="uppercase pl-3 italic text-2xl font-extrabold underline text-orange-600   decoration-orange-500">
          %10 İndirim !!
        </span>
        <p className="text-white mt-4 text-lg md:text-xl font-bold">
          Lezzet sizi bekliyor !
        </p>

        <Link
          to="/menu"
          className="mt-8 px-6 py-3 bg-orange-200 text-black hover:bg-orange-300  font-bold rounded-lg transition"
        >
          Menümüze Bakın
        </Link>
      </div>
    </div>
  );
}

export default Home;
